/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "../node_modules/ngx-sharebuttons/themes/default";
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  /* font-family: Pacifico,Pacifico,Pacifico !important; */
}
.style-success {
  background: #1ace44 !important;
  border: 1px solid #c3e6cb;
  color: white;
  margin-top: 350px !important;
}
.z-index-0 {
  z-index: 0 !important;
}

.cdk-overlay-container {
  overflow: auto;
}

.overlay {
  z-index: 1000000 !important;
  background-color: rgba(0, 0, 0, 0.5);
}
/* For checkout buttons */
.br-40px {
  border-radius: 40px !important;
}
.br-20px {
  border-radius: 20px !important;
}
.br-15px {
  border-radius: 15px !important;
}
.br-10px {
  border-radius: 10px !important;
}
.width1rem {
  width: 1rem !important;
}
.height1rem {
  height: 1rem !important;
}
.width1-5rem {
  width: 1.5rem !important;
}
.height1-5rem {
  height: 1.5rem !important;
}
.cursor-pointer {
  cursor: pointer;
}